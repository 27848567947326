import React, { useState } from "react";
import axios from "axios";
import DrawerComponent from "./Drawer";
import { Link, Redirect } from "react-router-dom";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { GetBaseUrl, GetToken, GetUserName, RemoveCredentials } from "utils";
import {
    Flex,
    Divider,
    Heading,
    Text,
    Avatar,
    Grid,
    useColorModeValue,
    Circle,
    Button,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    PopoverHeader,
    PopoverTrigger,
} from "@chakra-ui/core";

// PROPS
interface Props {
    heading: string;
    type: number;
    link: string;
}

// MAIN COMPONENT
export const TopNav: React.FC<Props> = ({ heading, type, link }) => {
    // Setting colors for info text
    const infoColor = useColorModeValue("theme_light", "theme_dark");

    // Logout API Endpoints and Call
    const [logoutState, handleLogoutState] = useState(false);
    function handleLogout() {
        const fetchItems = async () => {
            const base_url = GetBaseUrl();
            const session_key = GetToken();
            const logout_api_endpoint = "/api/v0/app/logout";
            await axios({
                method: "get",
                url: logout_api_endpoint,
                baseURL: base_url,
                headers: { Authorization: `Curious ${session_key}` },
            })
                .then(function (result) {
                    if (result.data.success) {
                        handleLogoutState(true);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        };
        fetchItems();
        // eslint-disable-next-line
    }

    // Handling Logout
    if (logoutState) {
        RemoveCredentials();
        return <Redirect to="/" />;
    }

    // BUILDING THE UI
    return (
        <>
            <DrawerComponent />
            {type === 1 && (
                <>
                    <Flex align="flex-end" justify="space-between">
                        <Heading
                            as="h1"
                            fontSize="18px"
                            borderBottom="2px solid #1170FF"
                            pb="10px"
                        >
                            {heading}
                        </Heading>
                        <Popover>
                            <PopoverTrigger>
                                <Flex align="center" pb="10px">
                                    <Avatar size="2xs" mr="3" />
                                    <Text>Hi, {GetUserName()}</Text>
                                </Flex>
                            </PopoverTrigger>
                            <PopoverContent w="200px">
                                <PopoverArrow />
                                <PopoverCloseButton />
                                <PopoverHeader>Are you sure ?</PopoverHeader>
                                <PopoverBody>
                                    <Button
                                        onClick={handleLogout}
                                        colorScheme="blue"
                                    >
                                        Logout
                                    </Button>
                                </PopoverBody>
                            </PopoverContent>
                        </Popover>
                    </Flex>
                    <Divider mt="0px" color="divider" />
                </>
            )}
            {type === 2 && (
                <Grid templateColumns="30px auto">
                    <Link to={link}>
                        <Flex
                            direction="column"
                            align="flex-start"
                            justify="center"
                            h="100%"
                        >
                            <ArrowBackIcon boxSize="20px" />
                        </Flex>
                    </Link>
                    <Grid templateRows="auto auto">
                        <Flex align="flex-end" justify="space-between">
                            <Heading
                                as="h1"
                                fontSize="18px"
                                borderBottom="2px solid #1170FF"
                                pb="10px"
                            >
                                {heading}
                            </Heading>
                            <Flex align="center" pb="10px" pr="5px">
                                <Circle bg="#969696" size="30px">
                                    <Text as="i" color={infoColor}>
                                        <b>i</b>
                                    </Text>
                                </Circle>
                            </Flex>
                        </Flex>
                        <Divider mt="0px" color="divider" />
                    </Grid>
                </Grid>
            )}
        </>
    );
};
