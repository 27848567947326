export default function GetBaseUrl() {
    var base_url = "";
    if ((window.location.hostname === "develop.d3kf51uamp6kd3.amplifyapp.com") || (window.location.hostname === "test.curiousfly.com") || (window.location.hostname === "localhost")) {
        base_url = "https://www.dev.curiousfly.com";
    }
    if ((window.location.hostname === "master.d3kf51uamp6kd3.amplifyapp.com") || (window.location.hostname === "app.curiousfly.com")) {
        base_url = "https://www.pro.curiousfly.com";
    }
    return base_url;
}
