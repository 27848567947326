import React, { useState } from "react";
import axios from "axios";
import { CflyDashApplicanceControl } from "assets/icons/dashboard";
import { GetBaseUrl, GetToken } from "utils";
import { Link } from "react-router-dom";
import {
    Box,
    Grid,
    Text,
    useColorModeValue,
    Switch,
    Square,
    Divider,
    Flex,
    Center,
    useToast,
} from "@chakra-ui/core";
import {
    load_active_gradient,
    shadow_dark_mode,
    shadow_day_mode,
} from "assets/theme/theme";

// PROPS
interface Props {
    id: string;
    name: string;
    status: number;
    now_running: number;
    days: number[];
    start_time: string;
    end_time: string;
}

// MAIN COMPONENT
export const SchedulerComponent: React.FC<Props> = ({
    name,
    status,
    id,
    now_running,
    days,
    start_time,
    end_time,
}) => {
    // Toggle Scheduler
    const [schedulerState, setSchedulerState] = useState(status);
    const toast = useToast();
    const fetchItems = async () => {
        const base_url = GetBaseUrl();
        const session_key = GetToken();
        const scheduler_state = schedulerState === 0 ? 1 : 0;
        const api_endpoint = "/api/v0/app/toggleScheduler";
        const api_data = {
            scheduler_id: id,
            status: scheduler_state,
        };
        await axios({
            method: "post",
            url: api_endpoint,
            baseURL: base_url,
            data: api_data,
            headers: {
                Authorization: `Curious ${session_key}`,
            },
        })
            .then(function (result) {
                console.log(result.data);
                if (!result.data.success) {
                    toast({
                        title: "Toggle Scheduler Failed",
                        description: result.data.message,
                        status: "error",
                        duration: 5000,
                        isClosable: true,
                    });
                } else {
                    if (schedulerState === 1) {
                        setSchedulerState(0);
                    } else {
                        setSchedulerState(1);
                    }
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    // Setting the shadow color
    const shadowColor = useColorModeValue(shadow_day_mode, shadow_dark_mode);

    // Setting the Day string for UI
    const scheduler_days: string[] = [];
    if (days[0] === 1) {
        scheduler_days.push("Sun");
    }
    if (days[1] === 1) {
        scheduler_days.push("Mon");
    }
    if (days[2] === 1) {
        scheduler_days.push("Tue");
    }
    if (days[3] === 1) {
        scheduler_days.push("Wed");
    }
    if (days[4] === 1) {
        scheduler_days.push("Thu");
    }
    if (days[5] === 1) {
        scheduler_days.push("Fri");
    }
    if (days[6] === 1) {
        scheduler_days.push("Sat");
    }

    //BUILDING THE UI
    return (
        <Box
            key={"SC" + id}
            bg={load_active_gradient}
            borderRadius="12px"
            boxShadow={shadowColor}
            h={{ base: "130px", md: "220px" }}
            w="100%"
            my="10px"
        >
            <Grid
                templateColumns="25% 50% 25%"
                placeItems="center"
                templateRows="60px"
            >
                <Link
                    to={"schedulers/" + id.toString() + "/" + name.toString()}
                >
                    <Square
                        bg="rgba(255,255,255,0.30)"
                        size="40px"
                        borderRadius="5px"
                    >
                        <CflyDashApplicanceControl h="30px" w="30px" />
                    </Square>
                </Link>
                <Text
                    textAlign="center"
                    fontSize={{ base: "20px", md: "30px" }}
                    color="white"
                >
                    {name}
                </Text>
                <Switch
                    colorScheme="teal"
                    size="md"
                    pr="5px"
                    isChecked={schedulerState === 1 ? true : false}
                    onChange={() => fetchItems()}
                />
            </Grid>
            <Flex align="center" justify="center" width="70%" mx="auto">
                <Text color="white">{start_time}</Text>
                <Divider color="white" mx="5px" />
                <Text color="white">{end_time}</Text>
            </Flex>
            <Center>
                <Text color="white">
                    {scheduler_days.length === 7
                        ? "All Days"
                        : scheduler_days.join(", ")}
                </Text>
            </Center>
        </Box>
    );
};
