import React, { useState } from "react";
import { CflyLightDim } from "assets/icons/loads";
import { Flex, Grid, Text, useColorModeValue } from "@chakra-ui/core";
import { SliderComponent } from "./SliderComp";
import {
    shadow_day_mode,
    shadow_dark_mode,
    load_active_gradient,
} from "assets/theme/theme";

// PROPS
interface Props {
    id: string;
    name: string;
    state: number;
    value: number;
    setDynamicState: React.Dispatch<React.SetStateAction<number>>;
}

// MAIN COMPONENT
export const LightLoad: React.FC<Props> = ({
    id,
    name,
    state,
    value,
    setDynamicState,
}) => {
    //Retrieving values fields
    const [inputState, setInputState] = useState(value);
    function updateState(event: number) {
        setInputState(event);
        setDynamicState(event);
    }

    //Setting the shadow color
    const shadowColor = useColorModeValue(shadow_day_mode, shadow_dark_mode);

    // BUILDING THE UI
    return (
        <Grid
            key={"L" + id}
            gridTemplateColumns="auto"
            gridTemplateRows="50% 50%"
            bg={state === 1 ? load_active_gradient : "load_inactive"}
            color="white"
            borderRadius="6px"
            pl="10px"
            h={{ base: "70px", md: "100px" }}
            boxShadow={shadowColor}
            opacity={state === 0 ? 0.35 : 1}
            mt="15px"
        >
            <Flex align="center" justify="space-between" pr="20px">
                <Text>{name}</Text>
                <CflyLightDim color="white" w="20px" h="20px" />
            </Flex>
            <Flex align="center" justify="space-around">
                <SliderComponent
                    type={2}
                    value={inputState}
                    state={state}
                    updater={updateState}
                />
                <Text>{inputState}</Text>
            </Flex>
        </Grid>
    );
};
