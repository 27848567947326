import React from "react";
import { createIcon } from "@chakra-ui/icon";

// Inside Dashboard Icons

export const CflyDashAccess = createIcon({
    displayName: "CflyDashAccess",
    viewBox: "0 0 24 24",
    path: (
        <g fill="#72C4A0">
            <path d="M5.843 11.335H2.909v-.7a.6.6 0 0 0-.3-.5.539.539 0 0 0-.577.05L.226 11.46a.564.564 0 0 0 0 .9l1.805 1.279a.5.5 0 0 0 .326.1.891.891 0 0 0 .251-.05.57.57 0 0 0 .3-.5v-.7h2.935a.557.557 0 0 0 .552-.552.6.6 0 0 0-.552-.602z" />
            <path d="M23.775 11.46l-1.806-1.276a.6.6 0 0 0-.577-.05.57.57 0 0 0-.3.5v.7h-2.934a.552.552 0 1 0 0 1.1h2.934v.7a.6.6 0 0 0 .3.5.452.452 0 0 0 .251.05.5.5 0 0 0 .326-.1l1.805-1.279a.536.536 0 0 0 .226-.451.452.452 0 0 0-.225-.394z" />
            <path d="M10.721 2.909h.7v2.934a.552.552 0 1 0 1.1 0V2.909h.7a.6.6 0 0 0 .5-.3.539.539 0 0 0-.05-.577L12.401.226a.4.4 0 0 0-.4-.226.536.536 0 0 0-.451.226l-1.277 1.805a.6.6 0 0 0-.05.577.6.6 0 0 0 .498.301z" />
            <path d="M5.943 5.141l.5-.5a.515.515 0 0 0 .125-.552.542.542 0 0 0-.426-.376l-2.157-.376a.541.541 0 0 0-.627.627l.376 2.182a.509.509 0 0 0 .376.426c.025.025.075.025.15.025a.534.534 0 0 0 .4-.176l.5-.5 2.081 2.081a.592.592 0 0 0 .4.176.564.564 0 0 0 .4-.953z" />
            <path d="M19.361 6.445a.592.592 0 0 0 .4.176.427.427 0 0 0 .176-.025.542.542 0 0 0 .376-.426l.376-2.182a.541.541 0 0 0-.627-.627l-2.207.326a.509.509 0 0 0-.426.376.542.542 0 0 0 .125.552l.5.5-2.081 2.081a.531.531 0 0 0 0 .777.592.592 0 0 0 .4.176.534.534 0 0 0 .4-.176l2.081-2.081z" />
            <path d="M12 8.3a3.657 3.657 0 0 0-1.379 7.046v1.279a.271.271 0 0 0 .075.176l.677.677a.214.214 0 0 1 0 .326l-.677.677a.228.228 0 0 0-.075.176v.652a.271.271 0 0 0 .075.176l.677.677a.214.214 0 0 1 0 .326l-.677.677a.228.228 0 0 0-.075.176v.953a.179.179 0 0 0 .075.15l1.153 1.2a.214.214 0 0 0 .326 0l1.153-1.2a.217.217 0 0 0 .075-.15v-6.973a3.643 3.643 0 0 0-1.4-7.021zm0 3.46a1.028 1.028 0 1 1 1.028-1.028A1.017 1.017 0 0 1 12 11.76z" />
        </g>
    ),
});

export const CflyDashApplicanceControl = createIcon({
    displayName: "CflyDashApplianceControl",
    viewBox: "0 0 24 24",
    path: (
        <g>
            <path
                d="M17.656 0H6.808A1.827 1.827 0 0 0 5 1.846v20.308A1.827 1.827 0 0 0 6.808 24h10.848a1.827 1.827 0 0 0 1.808-1.846V1.846A1.827 1.827 0 0 0 17.656 0zM9.52 2.769a1.847 1.847 0 1 1-1.808 1.846A1.827 1.827 0 0 1 9.52 2.769zm-.9 4.615h1.808a.923.923 0 0 1 0 1.846H8.616a.923.923 0 0 1 0-1.846zm0 2.769h1.808a.923.923 0 0 1 0 1.846H8.616a.923.923 0 0 1 0-1.846zm-.9 3.692a.914.914 0 0 1 .9-.923h1.808a.923.923 0 0 1 0 1.846H8.616a.914.914 0 0 1-.904-.922zm6.328 5.538h-.9v.923a.904.904 0 1 1-1.808 0v-.923h-.9a.923.923 0 0 1 0-1.846h.9v-.923a.904.904 0 1 1 1.808 0v.923h.9a.923.923 0 0 1 0 1.846zm1.808-4.615H14.04a.923.923 0 0 1 0-1.846h1.808a.923.923 0 0 1 0 1.846zm0-2.769H14.04a.923.923 0 0 1 0-1.846h1.808a.923.923 0 0 1 0 1.846zm0-2.769H14.04a.923.923 0 0 1 0-1.846h1.808a.923.923 0 0 1 0 1.846z"
                fill="#ff91f8"
            />
        </g>
    ),
});

export const CflyDashClimateControl = createIcon({
    displayName: "CflyDashClimateControl",
    viewBox: "0 0 24 24",
    path: (
        <g fill="#91E5FF">
            <path d="M9.449 20.18v-9.019a1.172 1.172 0 0 0-2.252-.456 1.168 1.168 0 0 0-.091.452v9.018a.428.428 0 0 1-.141.318 1.966 1.966 0 0 0 .9 3.388 2.092 2.092 0 0 0 1.662-.4 1.99 1.99 0 0 0 .722-1.52 1.91 1.91 0 0 0-.655-1.457.424.424 0 0 1-.145-.324zm-1.17 2.748a.965.965 0 0 1-.43-1.834v-7.833a.428.428 0 0 1 .856 0v7.831a.966.966 0 0 1-.429 1.834z" />
            <path d="M17.486 11.514a3.7 3.7 0 0 0 .075-.719 3.547 3.547 0 0 0-2.2-3.277.391.391 0 0 1-.092-.062.3.3 0 0 1-.029-.02.421.421 0 0 1-.127-.231c0-.008-.01-.013-.012-.021a2.8 2.8 0 0 0-2.8-2.442c-.01 0-.019 0-.029-.006a.274.274 0 0 1-.028 0 .552.552 0 0 0-.083 0h-.021a2.956 2.956 0 0 0-.989.23 2.746 2.746 0 0 0-.336.185 2.418 2.418 0 0 0-.241.163 3.147 3.147 0 0 0-.736.752.428.428 0 0 1-.708-.48 3.716 3.716 0 0 1 .438-.528c-.045-.02-.092-.054-.137-.072a.761.761 0 0 1-.057-.019 4.239 4.239 0 0 0-1.635-.325 4.312 4.312 0 0 0-4.186 3.273 3.133 3.133 0 0 1 .45-.042 3.159 3.159 0 0 1 2.821 1.791 2.019 2.019 0 0 1 3.383 1.5v3.192h3.781a3.549 3.549 0 0 0 2.988-1.614 2.618 2.618 0 0 0 .2-.347 3.3 3.3 0 0 0 .31-.881z" />
            <path d="M6.154 11.162a2.007 2.007 0 0 1 .091-.567 2.3 2.3 0 0 0-2.242-1.866 2.172 2.172 0 0 0-.836.156 2.274 2.274 0 0 0-1.316 1.4 2.49 2.49 0 0 1 1.209.441.428.428 0 0 1-.493.7 1.736 1.736 0 0 0-1.207-.291 1.62 1.62 0 0 0 .254 3.219h4.54z" />
            <path d="M23.326 8.586c-.55-.523-.718-.763-.718-1.022s.169-.489.722-1.016c.429-.419.73-.738.659-1.016s-.527-.407-1.109-.553c-.766-.185-.965-.3-1.079-.466-.146-.283-.092-.576.082-1.19.153-.564.292-1.077.082-1.285s-.719-.072-1.219.063c-.9.288-1.1.187-1.209.133-.216-.145-.333-.345-.518-1.113-.121-.478-.26-1.026-.56-1.108-.281-.077-.655.3-1.012.665-.522.55-.759.718-1.011.718-.176 0-.416-.056-1.023-.731-.371-.371-.725-.736-1-.653s-.407.5-.557 1.084l-.04.17c-.146.543-.238.814-.455.95-.175.1-.385.155-1.237-.111-.615-.165-1.023-.258-1.227-.06s-.086.653.08 1.226c.041.147.128.555.2.9a3.668 3.668 0 0 1 .995-.246 5.614 5.614 0 1 1 5.984 8.983c-.041.075-.076.146-.126.227a2.406 2.406 0 0 1-.616 1.281l.045.048c.3.309.653.67.915.67a.642.642 0 0 0 .111-.013c.277-.075.415-.628.537-1.114.195-.81.321-.965.443-1.062a.391.391 0 0 1 .1-.06c.2-.089.478-.054 1.165.14.578.166 1.035.278 1.234.077s.1-.608-.06-1.207c-.206-.73-.251-1.007-.129-1.23.164-.239.428-.329 1-.487 0 0 .1-.029.106-.029.626-.157 1.036-.279 1.111-.559s-.303-.65-.666-1.004z" />
            <path d="M18.575 11.822a4.76 4.76 0 1 0-5.249-7.85c.053.011.111.007.163.019a3.718 3.718 0 0 1 2.7 2.842 4.382 4.382 0 0 1 2.477 3.42.391.391 0 0 1 .017.084c0 .014-.007.025-.008.039.013.139.031.277.031.419a4.37 4.37 0 0 1-.093.888c-.01.05-.026.09-.038.139z" />
            <path d="M6.153 15.175h-.952a.428.428 0 1 0 0 .856h.953a.428.428 0 1 0 0-.856z" />
            <path d="M6.153 16.529h-.952a.428.428 0 1 0 0 .856h.953a.428.428 0 1 0 0-.856z" />
            <path d="M6.581 18.312a.428.428 0 0 0-.428-.428h-.952a.428.428 0 0 0 0 .856h.953a.428.428 0 0 0 .427-.428z" />
        </g>
    ),
});

export const CflyDashFeatures = createIcon({
    displayName: "CflyDashFeatures",
    viewBox: "0 0 24 24",
    path: (
        <g fill="#FF91C4">
            <path d="M6.231 2.088l-1.612-.12a.435.435 0 0 1-.337-.241L3.656.235a.4.4 0 0 0-.722 0l-.625 1.492a.362.362 0 0 1-.337.241l-1.612.12a.393.393 0 0 0-.217.7L1.37 3.845a.343.343 0 0 1 .124.385l-.385 1.588a.408.408 0 0 0 .6.433l1.4-.842a.413.413 0 0 1 .409 0l1.4.842a.406.406 0 0 0 .6-.433L5.124 4.23a.444.444 0 0 1 .12-.385l1.228-1.059a.4.4 0 0 0-.241-.698z" />
            <path d="M10.691 4.952h12.081a1.228 1.228 0 0 0 0-2.455H10.691a1.228 1.228 0 1 0 0 2.455z" />
            <path d="M6.231 10.691l-1.612-.12a.388.388 0 0 1-.337-.241l-.626-1.492a.4.4 0 0 0-.722 0l-.626 1.492a.362.362 0 0 1-.337.241l-1.612.12a.393.393 0 0 0-.217.7l1.228 1.057a.343.343 0 0 1 .12.385l-.385 1.588a.408.408 0 0 0 .6.433l1.4-.842a.413.413 0 0 1 .409 0l1.4.842a.406.406 0 0 0 .6-.433l-.39-1.588a.444.444 0 0 1 .12-.385l1.227-1.059a.4.4 0 0 0-.24-.698z" />
            <path d="M22.772 11.186H10.691a1.228 1.228 0 1 0 0 2.455h12.081a1.228 1.228 0 0 0 0-2.455z" />
            <path d="M6.231 19.322l-1.612-.12a.388.388 0 0 1-.337-.241l-.626-1.492a.4.4 0 0 0-.722 0l-.626 1.492a.362.362 0 0 1-.337.241l-1.612.12a.393.393 0 0 0-.217.7l1.227 1.059a.343.343 0 0 1 .12.385l-.385 1.588a.408.408 0 0 0 .6.433l1.4-.842a.414.414 0 0 1 .409 0l1.4.842a.406.406 0 0 0 .6-.433l-.389-1.59a.444.444 0 0 1 .12-.385l1.227-1.059a.4.4 0 0 0-.24-.698z" />
            <path d="M22.772 19.755H10.691a1.228 1.228 0 1 0 0 2.455h12.081a1.233 1.233 0 0 0 1.227-1.227 1.217 1.217 0 0 0-1.227-1.228z" />
        </g>
    ),
});

export const CflyDashLight = createIcon({
    displayName: "CflyDashLight",
    viewBox: "0 0 24 24",
    path: (
        <g fill="#e6e66d">
            <path d="M17.172 21.283h-9.96a5.922 5.922 0 0 0 9.96 0z" />
            <path d="M8.849 7.748V9.47L1.742 20.447h20.9L15.536 9.47V7.748h-2.925V.861h5.434V0H6.34v.861h5.434v6.887z" />
        </g>
    ),
});

export const CflyDashMusic = createIcon({
    displayName: "CflyDashMusic",
    viewBox: "0 0 24 24",
    path: (
        <path
            d="M20 0H4a4 4 0 0 0-4 4v16a4 4 0 0 0 4 4h16a4 4 0 0 0 4-4V4a4 4 0 0 0-4-4zm-.915 15.733A2.933 2.933 0 1 1 16.267 12h.267V7.467l-5.867 1.107v7.96a2.72 2.72 0 0 1-1.493 2.587 2.945 2.945 0 1 1-1.44-5.52H8V7.467a1.333 1.333 0 0 1 1.088-1.312l8.533-1.6A1.333 1.333 0 0 1 19.2 5.867V15.2a1.333 1.333 0 0 1-.115.533z"
            fill="#ff9191"
        />
    ),
});

export const CflyDashSecurity = createIcon({
    displayName: "CflyDashSecurity",
    viewBox: "0 0 24 24",
    path: (
        <g fill="#7ce68e">
            <path d="M22.193 12.863v.336a.286.286 0 0 1-.286.282h-4.146v.724h4.146a1.009 1.009 0 0 0 1.009-1.006v-.336a1.331 1.331 0 1 0-.724 0z" />
            <path d="M22.916 19.557v-.333a1.006 1.006 0 0 0-1.009-1.006h-4.146v.724h4.146a.286.286 0 0 1 .286.282v.333a1.328 1.328 0 1 0 .724 0z" />
            <path d="M1.694 19.557v-.333a.282.282 0 0 1 .286-.282h4.146v-.725H1.98a1.006 1.006 0 0 0-1.009 1.006v.334a1.331 1.331 0 1 0 .724 0z" />
            <path d="M.971 12.863v.336a1.009 1.009 0 0 0 1.009 1.017h4.146v-.724H1.98a.282.282 0 0 1-.286-.282v-.336a1.328 1.328 0 1 0-.724 0z" />
            <path d="M8.021 9.33V7.64a3.922 3.922 0 1 1 7.843 0v1.69h2.265a2.17 2.17 0 0 1 .268 0V7.454a6.454 6.454 0 1 0-12.908 0v1.892a2.169 2.169 0 0 1 .268 0z" />
            <path d="M17.037 17.493h2.894v-2.554h-2.894v-2.171h2.894v-.912a1.809 1.809 0 0 0-1.809-1.809H5.757a1.809 1.809 0 0 0-1.809 1.81v.911h2.894v2.171H3.948v2.565h2.894v2.171H3.948v.893a1.809 1.809 0 0 0 1.809 1.809H18.13a1.809 1.809 0 0 0 1.809-1.809v-.9h-2.891zm-4.732-.745v1.4h-.724v-1.4a1.447 1.447 0 1 1 .724 0z" />
        </g>
    ),
});

export const CflyDashShades = createIcon({
    displayName: "CflyDashShades",
    viewBox: "0 0 24 24",
    path: (
        <g fill="#98bde1">
            <path d="M1.447 16.129a1.04 1.04 0 0 0 .9 1.135h9.421v3.8a1.448 1.448 0 0 0-1.107 1.458 1.344 1.344 0 1 0 2.674 0 1.441 1.441 0 0 0-1.158-1.467v-3.792h9.469a1.04 1.04 0 0 0 .9-1.135V2.663h-21.1zM6.306 5.713h11.388a.227.227 0 0 1 0 .453H6.306a.227.227 0 0 1 0-.453zm0 4.024h11.388a.227.227 0 0 1 0 .453H6.306a.227.227 0 0 1 0-.453zm0 4.025h11.388a.227.227 0 0 1 0 .453H6.306a.227.227 0 0 1 0-.453z" />
            <path d="M22.826-.002H1.171a1.137 1.137 0 1 0 0 2.273h21.655a1.137 1.137 0 1 0 0-2.273z" />
        </g>
    ),
});

export const CflyDashUsageStatistics = createIcon({
    displayName: "CflyDashUsageStatistics",
    viewBox: "0 0 24 24",
    path: (
        <g fill="#E1A798">
            <path d="M3.376 12.44h3.376v5.065H3.376z" />
            <path d="M13.75 10.751h3.376v6.751H13.75z" />
            <path d="M8.594 5.689h3.376v11.816H8.594z" />
            <path d="M18.935 14.129h3.376v3.376h-3.376z" />
            <path d="M1.715 19.192V4H0v16.881h24v-1.689z" />
        </g>
    ),
});

export const CflyDashVideo = createIcon({
    displayName: "CflyDashVideo",
    viewBox: "0 0 24 24",
    path: (
        <path
            d="M2.341 3A2.349 2.349 0 0 0 0 5.366V18.38a2.349 2.349 0 0 0 2.341 2.366h19.318A2.349 2.349 0 0 0 24 18.379V5.366A2.349 2.349 0 0 0 21.659 3zm7.61 4.732l6.439 4.141-6.439 4.141z"
            fill="#a698e1"
        />
    ),
});
