import React from "react";
import { createIcon } from "@chakra-ui/icon";

export const CflyDefaultRoom = createIcon({
    displayName: "CflyDefaultRoom",
    viewBox: "0 0 24 24",
    path: (
        <g fill="currentColor">
            <path d="M3.477 11.719a2.262 2.262 0 0 1 2.3-2.216h2.7a2.262 2.262 0 0 1 2.3 2.216v.129h2.435v-.129a2.262 2.262 0 0 1 2.3-2.216h2.7a2.262 2.262 0 0 1 2.3 2.216v.129h2.435V7.81a3.592 3.592 0 0 0-3.643-3.518H4.695A3.592 3.592 0 0 0 1.042 7.81v4.038h2.435z" />
            <path d="M23.025 12.63H.974a.946.946 0 0 0-.974.913v3.517h24v-3.517a.946.946 0 0 0-.975-.913z" />
            <path d="M0 20.707h3.241a1.732 1.732 0 0 1 1.748-1.566H19.01a1.732 1.732 0 0 1 1.748 1.563H24v-2.863H0z" />
        </g>
    ),
});

export const CflyHall = createIcon({
    displayName: "CflyHall",
    viewBox: "0 0 24 24",
    path: (
        <g fill="currentColor">
            <path d="M7.93 11.435H1.939L.998 4.454a.5.5 0 0 0-.99.1l.99 7.377v8.415a.5.5 0 0 0 .99 0v-.99h5.447v.99a.5.5 0 0 0 .99 0V11.93a.5.5 0 0 0-.495-.495z" />
            <path d="M23.548 4.008a.492.492 0 0 0-.545.446l-.941 6.981h-5.985a.5.5 0 0 0-.5.5v8.411a.5.5 0 0 0 .99 0v-.99h5.446v.99a.5.5 0 0 0 .99 0v-8.367l.99-7.377a.512.512 0 0 0-.445-.594z" />
            <path d="M14.011 19.851h-1.508V6.979h6.532a.5.5 0 1 0 0-.99H4.964a.5.5 0 1 0 0 .99h6.532v12.872H9.991a.5.5 0 1 0 0 .99h4.02a.5.5 0 1 0 0-.99z" />
        </g>
    ),
});

export const CflyKitchen = createIcon({
    displayName: "CflyKitchen",
    viewBox: "0 0 24 24.084",
    path: (
        <g fill="currentColor">
            <path d="M4.26 0a.414.414 0 0 0-.412.415v4.252a.414.414 0 0 0 .412.415h16.091a.414.414 0 0 0 .412-.415V.415A.414.414 0 0 0 20.351 0H4.26z" />
            <path d="M4.26 5.587a.414.414 0 0 0-.412.415v-.066l-.827 2.557a.417.417 0 0 0 .058.372.412.412 0 0 0 .333.172l17.786.028a.412.412 0 0 0 .334-.171.418.418 0 0 0 .059-.373l-.847-2.647a.413.413 0 0 0-.392-.288H4.26z" />
            <path d="M5.33 10.689a.414.414 0 0 0-.413.415v1.6a.414.414 0 0 0 .413.415h2.884a.414.414 0 0 0 .413-.415v-1.6a.414.414 0 0 0-.413-.415z" />
            <path d="M19.28 12.28l-2.327.006a.415.415 0 0 0 0 .831l2.327-.006a.415.415 0 0 0 0-.831z" />
            <path d="M3.419 13.512a.415.415 0 0 0 0 .831h17.773a.415.415 0 0 0 0-.831z" />
            <path d="M3.426 14.897a.414.414 0 0 0-.413.415v8.357a.414.414 0 0 0 .413.415h17.759a.414.414 0 0 0 .413-.415v-8.357a.414.414 0 0 0-.413-.415zm10.239 1.8h5.651a.414.414 0 0 1 .413.415v4.09a.414.414 0 0 1-.413.415h-5.651a.414.414 0 0 1-.413-.415v-4.09a.414.414 0 0 1 .413-.415zm-8.368.664h5.65a.414.414 0 0 1 .413.415v4.09a.414.414 0 0 1-.413.415h-5.65a.414.414 0 0 1-.413-.415v-4.09a.414.414 0 0 1 .413-.415z" />
            <path d="M14.078 17.53h4.826v3.26h-4.826z" />
            <path d="M5.711 18.195h4.825v3.259H5.711v-3.259z" />
        </g>
    ),
});

export const CflyLivingRoom = createIcon({
    displayName: "CflyLivingRoom",
    viewBox: "0 0 24 24",
    d:
        "M11326.882 12315.307h.228a2.043 2.043 0 0 0 2.027-2.055v-1.141h-16.235v1.141a2.042 2.042 0 0 0 2.027 2.055h.228v1.146a.223.223 0 0 0 .228.224h.446a.224.224 0 0 0 .228-.224v-1.146h9.92v1.146a.222.222 0 0 0 .228.224h.447a.224.224 0 0 0 .228-.224zm-6.087-6.92a2.125 2.125 0 0 0-2.049-1.19l-3.62.242a2.4 2.4 0 0 0-2.222 2.389v1.826h7.891zm.451 0a2.125 2.125 0 0 1 2.049-1.19l3.62.242a2.4 2.4 0 0 1 2.222 2.389v1.826h-7.891zm12.4-1.3h-1.8a.455.455 0 0 1-.441-.552l.674-3.217a1.612 1.612 0 0 1 1.529-1.251h.978a1.619 1.619 0 0 1 1.533 1.253l.674 3.217a.455.455 0 0 1-.441.552h-1.8v9.359a.226.226 0 0 1-.228.229h-.446a.225.225 0 0 1-.228-.229v-9.359zm-.038-4.109a.71.71 0 0 0-.651.53l-.559 2.667h3.412l-.574-2.666a.719.719 0 0 0-.651-.53zm4.321 15.066a.225.225 0 0 0 .226-.231v-.446a.231.231 0 0 0-.226-.231h-25.7a.225.225 0 0 0-.226.231v.452a.231.231 0 0 0 .226.231z",
});

export const CflyOfficeRoom = createIcon({
    displayName: "CflyOfficeRoom",
    viewBox: "0 0 24 24",
    d:
        "M11285.802 12338.974l-.318 4.088h2.995c.206-.709.409-1.41.57-2.132.279-1.256.446-2.32-1.166-2.464a3.982 3.982 0 0 0-2.081.508zm-1.423 4.088l.346-4.455v-.036l.282-3.62c.134-1.317.134-2.792-1.6-2.873-2.251-.1-4.952-.1-7.2 0-1.757.082-1.742 1.584-1.604 2.906l.282 3.622.347 4.455zm-13.247 0h2.995l-.318-4.088a3.977 3.977 0 0 0-2.081-.508c-1.611.143-1.445 1.208-1.166 2.464.161.721.364 1.423.57 2.131zm20.9 2.762h-24.115v10.4h24.113v-10.4zm1.576-1.806h-27.267v1.082h27.266z",
});

export const CflyBathRoom = createIcon({
    displayName: "CflyBathRoom",
    viewBox: "0 0 24 24",
    d:
        "M3.13 3.73A3.694 3.694 0 0 1 6.782 0a3.609 3.609 0 0 1 2.65 1.162l.186-.19a1.541 1.541 0 0 1 2.216 0l.738.754a.541.541 0 0 1 0 .754L9.989 5.12a.513.513 0 0 1-.738 0l-.738-.754a1.623 1.623 0 0 1 0-2.265l.178-.182a2.577 2.577 0 0 0-1.911-.85 2.639 2.639 0 0 0-2.609 2.663v5.87h1.044v-.8a.262.262 0 0 1 .263-.269h.521a.266.266 0 0 1 .263.262v2.676a.258.258 0 0 1-.263.261h-.517a.263.263 0 0 1-.263-.269v-.8H4.176v2.646c5.152-.024 16-.018 18.04.02h1.525a.266.266 0 0 1 .261.269v.528a.262.262 0 0 1-.261.269h-.882l-.82 3.911a4.589 4.589 0 0 1-4.3 3.556v1.872a.261.261 0 0 1-.263.261h-.517a.259.259 0 0 1-.263-.261v-1.872H7.304v1.872a.261.261 0 0 1-.263.261h-.517a.259.259 0 0 1-.263-.261v-1.872a4.592 4.592 0 0 1-4.3-3.556l-.82-3.911H.259a.266.266 0 0 1-.261-.269V13.6a.262.262 0 0 1 .261-.269h1.525c.287-.005.748-.01 1.344-.014z",
});

export const CflyLandscape = createIcon({
    displayName: "CflyLandscape",
    viewBox: "0 0 24.095 24",
    d:
        "M13.465 10.426h5.669a.671.671 0 0 1 .567.366l4.252 7.323c.35.6.017 1.465-.567 1.465H.71c-.584 0-.917-.861-.567-1.465l4.252-7.323a.671.671 0 0 1 .567-.366v1.831a2.081 2.081 0 0 0 1.772 2.288h4.96a2.081 2.081 0 0 0 1.772-2.288zm-3.543 2.746v-2.288a.416.416 0 0 0-.354-.458h-.709a.416.416 0 0 0-.354.458v2.289H6.734a.832.832 0 0 1-.709-.915V7.681a.989.989 0 0 1 .357-.795l2.48-1.831a.573.573 0 0 1 .7 0l2.48 1.831a.989.989 0 0 1 .357.795v4.577a.832.832 0 0 1-.709.915z",
});
