import React, { useState } from "react";
import axios from "axios";
import { Circle, Grid, Switch, Text, useToast } from "@chakra-ui/core";
import { CflyDefaultScene } from "assets/icons/scene";
import { load_active_gradient } from "assets/theme/theme";
import { GetBaseUrl, GetToken } from "utils";
import { Link } from "react-router-dom";

// Props
interface Props {
    name: string;
    id: string;
    status: number;
}

//MAIN COMPONENT
export const SceneComponent: React.FC<Props> = ({ name, id, status }) => {
    // Toggle Scene
    const [sceneState, setSceneState] = useState(status);
    const toast = useToast();
    const fetchItems = async () => {
        const base_url = GetBaseUrl();
        const session_key = GetToken();
        const scene_state = sceneState === 0 ? 1 : 0;
        const api_endpoint = "/api/v0/app/toggleScene";
        const api_data = {
            scene_id: id,
            status: scene_state,
        };
        await axios({
            method: "post",
            url: api_endpoint,
            baseURL: base_url,
            data: api_data,
            headers: {
                Authorization: `Curious ${session_key}`,
            },
        })
            .then(function (result) {
                console.log(result.data);
                if (!result.data.success) {
                    toast({
                        title: "Toggle Scene Failed",
                        description: result.data.message,
                        status: "error",
                        duration: 5000,
                        isClosable: true,
                    });
                } else {
                    if (sceneState === 1) {
                        setSceneState(0);
                    } else {
                        setSceneState(1);
                    }
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    return (
        <>
            <Grid
                templateColumns="30% auto 20%"
                key={"S" + id}
                bg={load_active_gradient}
                height="90px"
                mt="15px"
                placeItems="center"
                borderRadius="12px"
            >
                <Link to={"scenes/" + id.toString() + "/" + name.toString()}>
                    <Circle bg="rgba(255,255,255, 0.5)" size="55px">
                        <Circle bg="tomato" size="40px">
                            <CflyDefaultScene h="22px" w="22px" color="white" />
                        </Circle>
                    </Circle>
                </Link>
                <Text color="white">{name}</Text>
                <Switch
                    size="md"
                    isChecked={sceneState === 1 ? true : false}
                    colorScheme="teal"
                    onChange={() => fetchItems()}
                />
            </Grid>
        </>
    );
};
