import { theme } from "@chakra-ui/core";
import { merge } from "@chakra-ui/utils";
import { extendTheme } from "@chakra-ui/core";
import { mode } from "@chakra-ui/theme-tools";

const curiousTheme = merge(theme, {
    colors: {
        ...theme.colors,
        theme_dark: "#141414",
        theme_light: "#f5f5f7",
        dark_main_nav: "#242424",
        divider: "#707070",
        room: "#F65251",
        main_nav_icon_light: "#2467CC",
        load_inactive: "#313131",
        load_temporary: "#1b998b",
        slider_fill_active: "#1E70DA",
        slider_track_active: "#1a3f7d",
        slider_track_inactive: "#101010",
        new_blue: "#2176D5",
        new_red: "#E16464",
    },
});

const overrides = extendTheme({
    styles: {
        global: (props: any) => ({
            body: {
                color: mode("gray.800", "whiteAlpha.900")(props),
                bg: mode("theme_light", "theme_dark")(props),
            },
        }),
    },
    config: { useSystemColorMode: true },
});

// Shadow Colors
const shadow_dark_mode = "0px 2px 4px black";
const shadow_day_mode = "5px 5px 10px rgba(0,0,0,0.2)";
//Load Colors
const load_active_gradient = "linear-gradient(to bottom, #2362D8, #1C4CA6)";
//Dashboard Colors
const dash_dark_mode = "linear-gradient(to top, #141414, #373737)";
//Appliance Control (Room Colors)
const room_component = "linear-gradient(to bottom, #FC7D7D, #C24646)";

// Exports
export default merge(curiousTheme, overrides);
export {
    shadow_dark_mode,
    shadow_day_mode,
    load_active_gradient,
    dash_dark_mode,
    room_component,
};
