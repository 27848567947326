import React, {useState} from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { GetBaseUrl, GetToken } from "utils/index";
import { CflyDefaultRoom } from "assets/icons/rooms";
import { shadow_day_mode, shadow_dark_mode } from "assets/theme/theme";
import {
    useToast,
    Box,
    Flex,
    Switch,
    Text,
    useColorModeValue,
} from "@chakra-ui/core";

// MAIN COMPONENT
export default function RoomComponent(props: any) {
    const toast = useToast();

    const [roomLive, setRoomLive] = useState<boolean>(false);

    if (props.live === 1) {
        setRoomLive(true);
    }

    const fetchItems = async () => {
        const base_url = GetBaseUrl();
        const session_key = GetToken();
        const room_state = roomLive === false ? 1 : 0;
        const api_endpoint = "/api/v0/app/toggleRoomLive";
        const api_data = {
            room_id: props.id,
            status: room_state,
        };
        await axios({
            method: "post",
            url: api_endpoint,
            baseURL: base_url,
            data: api_data,
            headers: {
                Authorization: `Curious ${session_key}`,
            },
        })
            .then(function (result) {
                console.log(result.data);
                if (!result.data.success) {
                    toast({
                        title: "Toggle Room Live Failed",
                        description: result.data.message,
                        status: "error",
                        duration: 5000,
                        isClosable: true,
                    });
                } else {
                    setRoomLive(!roomLive);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    //Setting the shadow color
    const shadowColor = useColorModeValue(shadow_day_mode, shadow_dark_mode);

    // BUILDING THE UI
    return (
        <Box
            bg="new_red"
            borderRadius="12px"
            boxShadow={shadowColor}
            h={{ base: "120px", md: "220px" }}
            w={{ base: "120px", md: "220px" }}
        >
            <Flex
                direction="column"
                align="flex-end"
                justify="center"
                h={{ base: "30px", md: "40px" }}
            >
                <Switch
                    colorScheme="teal"
                    size="md"
                    pr="5px"
                    isChecked={roomLive}
                    onChange={() => fetchItems()}
                />
            </Flex>
            <Link to={"/rooms/" + props.id + "/" + props.name}>
                <Flex
                    direction="column"
                    align="center"
                    justify="center"
                    h={{ base: "90px", md: "180px" }}
                >
                    <CflyDefaultRoom h="50px" w="50px" color="white" />
                    <Text
                        mt="10px"
                        w="80%"
                        textAlign="center"
                        fontSize={{ base: "12px", md: "20px" }}
                        color="white"
                    >
                        {props.name}
                    </Text>
                </Flex>
            </Link>
        </Box>
    );
}
