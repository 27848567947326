import { useEffect, useState } from "react";
import axios from "axios";
import {
    GetBaseUrl,
    GetToken,
    GetValueFromObject,
    RemoveCredentials,
} from "utils/index";

export default function UseGetAPi(
    api_endpoint: string,
    extract_data: string | null
) {
    //States for the API Call
    const [apiDataState, apiSetDataState] = useState([]);
    const [apiSuccessState, apiSetSuccessState] = useState(false);
    const [apiMessageState, apiSetMessageState] = useState("");

    useEffect(() => {
        const fetchItems = async () => {
            const base_url = GetBaseUrl();
            const session_key = GetToken();
            await axios({
                method: "get",
                url: api_endpoint,
                baseURL: base_url,
                headers: { Authorization: `Curious ${session_key}` },
            })
                .then(function (result) {
                    if (extract_data) {
                        apiSetDataState(
                            GetValueFromObject(result.data.data, extract_data)
                        );
                    } else {
                        apiSetDataState(result.data.data);
                    }
                    apiSetSuccessState(result.data.data.success);
                    apiSetMessageState(result.data.data.message);
                })
                .catch(function (error) {
                    if (error.response.status === 401) {
                        RemoveCredentials();
                        window.location.replace("/");
                    } else {
                        console.log(error);
                    }
                });
        };
        fetchItems();
        // eslint-disable-next-line
    }, []);

    return { apiDataState, apiSuccessState, apiMessageState };
}
