import React from "react";
import { createIcon } from "@chakra-ui/icon";

export const CflyDefaultLoad = createIcon({
    displayName: "CflyDefaultLoad",
    viewBox: "0 0 24 24",
    path: (
        <g fill="currentColor">
            <path d="M20.795 11.897l-7.856-6.185a1.472 1.472 0 0 0-1.879 0l-7.855 6.182a1.639 1.639 0 0 0-.593 1.255v8.634a.735.735 0 0 0 .747.718h17.279a.735.735 0 0 0 .747-.718v-8.638a1.63 1.63 0 0 0-.59-1.248zm-5.6 4.806h-1.9v1.831a1.289 1.289 0 0 1-2.576 0v-1.83H8.805a1.239 1.239 0 1 1 0-2.476h1.9v-1.831a1.289 1.289 0 0 1 2.576 0v1.831h1.9a1.239 1.239 0 1 1 0 2.476z" />
            <path d="M23.626 9.837L12.655 1.032a1.095 1.095 0 0 0-1.31 0L7.316 4.265a.288.288 0 0 1-.47-.214v-1.61a.732.732 0 0 0-.741-.718h-1.97a.732.732 0 0 0-.741.718v4.664a.655.655 0 0 1-.248.511L.375 9.837a.955.955 0 0 0-.222 1.27 1.009 1.009 0 0 0 1.462.222l9.578-7.687a1.3 1.3 0 0 1 1.617 0l9.578 7.687a1.009 1.009 0 0 0 1.462-.222.955.955 0 0 0-.224-1.27z" />
        </g>
    ),
});

export const CflyAcFan = createIcon({
    displayName: "CflyAcFan",
    viewBox: "0 0 24 24",
    path: (
        <g fill="currentColor">
            <path d="M14.015 13.655a2.414 2.414 0 0 1-1.652.86c.063 3.958-3.178 3.879-2.092 6.639 1.07 1.982 2.02 2.364 4.115 1.319 5.068-2.55 2.002-6.577-.371-8.818z" />
            <path d="M10.257 10.612a2.42 2.42 0 0 1 1.414-.876c-.186-4.174 3.19-4.043 2.085-6.851C12.686.9 11.736.521 9.641 1.566c-5.245 2.64-1.778 6.86.616 9.046z" />
            <path d="M9.777 12.529c-4.216.451-4.257-2.966-7.013-1.7C.843 12.006.513 12.975 1.67 15.01c2.9 5.05 6.877 1.467 8.945-1.04a2.419 2.419 0 0 1-.838-1.441z" />
            <path d="M22.288 9.133c-2.85-4.971-6.752-1.577-8.846.922a2.421 2.421 0 0 1 1.078 1.526c3.883-.238 3.994 2.961 6.675 1.733 1.921-1.177 2.252-2.146 1.093-4.181z" />
            <path d="M12.519 11.242a.9.9 0 0 0-.38-.1.907.907 0 0 0-.6 1.615.9.9 0 0 0 .533.2.876.876 0 0 0 .12 0 .907.907 0 0 0 .326-1.711z" />
        </g>
    ),
});

export const CflyAcOrientation = createIcon({
    displayName: "CflyAcOrientation",
    viewBox: "0 0 24 24",
    path: (
        <g fill="currentColor">
            <path d="M17.256 12.016a5.508 5.508 0 1 1 5.508-5.508 5.514 5.514 0 0 1-5.508 5.508z" />
            <path d="M18.411 12.431a6.327 6.327 0 0 1-2.309 0v4.839h-1.723l2.877 4.984 2.878-4.984h-1.723z" />
            <path d="M13.515 11.547a6.769 6.769 0 0 1-1.633-1.632L8.46 13.337l-1.219-1.219-1.489 5.559 5.559-1.488-1.219-1.219z" />
            <path d="M10.721 6.508a6.734 6.734 0 0 1 .106-1.154H5.984V3.63L1 6.508l4.984 2.878V7.662h4.837a6.729 6.729 0 0 1-.1-1.154z" />
        </g>
    ),
});

export const CflyAc = createIcon({
    displayName: "CflyAc",
    viewBox: "0 0 24 24",
    path: (
        <g fill="currentColor">
            <path d="M13.227 8.816a.791.791 0 0 1-.568-.239.8.8 0 0 0-1.1 0 .793.793 0 1 1-1.136-1.108 2.422 2.422 0 0 1 3.374 0 .793.793 0 0 1-.568 1.348z" />
            <path d="M14.917 7.126a.791.791 0 0 1-.558-.229 3.269 3.269 0 0 0-4.5 0 .793.793 0 1 1-1.113-1.128 4.778 4.778 0 0 1 6.734 0 .793.793 0 0 1-.558 1.358z" />
            <path d="M16.599 5.444a.791.791 0 0 1-.559-.231 5.566 5.566 0 0 0-7.863 0 .794.794 0 0 1-1.119-1.126 7.154 7.154 0 0 1 10.1 0 .793.793 0 0 1-.559 1.357z" />
            <path d="M1 20.052v.2a2.383 2.383 0 0 0 2.38 2.38h17.457a2.383 2.383 0 0 0 2.38-2.38v-.2z" />
            <path d="M20.837 9.935H3.38A2.383 2.383 0 0 0 1 12.315v6.546h22.217v-6.546a2.383 2.383 0 0 0-2.38-2.38zm-1.587 4.562h-1.587a.6.6 0 1 1 0-1.19h1.587a.6.6 0 0 1 0 1.19z" />
        </g>
    ),
});

export const CflyFan = createIcon({
    displayName: "CflyFan",
    viewBox: "0 0 24 24",
    d:
        "M13.682 12.006a1.691 1.691 0 1 1-1.691-1.694 1.692 1.692 0 0 1 1.691 1.694zm.845-10.549a24.812 24.812 0 0 1-2.536 7.2.372.372 0 0 1-.719-.11v-7.2A.36.36 0 0 1 11.64 1h2.536a.351.351 0 0 1 .351.462m-13.067 8a.351.351 0 0 0-.461.351v2.541a.36.36 0 0 0 .347.368h7.186a.373.373 0 0 0 .11-.72 24.628 24.628 0 0 0-7.186-2.541m8.032 13.072a.351.351 0 0 0 .351.462h2.536a.36.36 0 0 0 .368-.347v-7.2a.372.372 0 0 0-.719-.11 24.729 24.729 0 0 0-2.536 7.2m13.05-8.046a.351.351 0 0 0 .461-.351V11.6a.36.36 0 0 0-.347-.368h-7.186a.373.373 0 0 0-.11.72 24.628 24.628 0 0 0 7.186 2.541",
});

export const CflyLightDim = createIcon({
    displayName: "CflyLightDim",
    viewBox: "0 0 24 24",
    path: (
        <g fill="currentColor">
            <path d="M16.068 20.023H6.987a5.4 5.4 0 0 0 9.081 0z" />
            <path d="M8.479 7.92v1.538L2 19.258h19.056l-6.479-9.8V7.92h-2.668V1.769h4.955V1H6.192v.769h4.955V7.92z" />
        </g>
    ),
});

export const CflyLightNoDim = createIcon({
    displayName: "CflyLightNoDim",
    viewBox: "0 0 24 24",
    d:
        "M15.963 16.226H12.7V7.525a1.088 1.088 0 0 0-2.175 0v8.7H7.263v-1.364a7.613 7.613 0 1 1 8.7 0zm0 2.175v2.175a2.175 2.175 0 0 1-2.175 2.175h-4.35a2.175 2.175 0 0 1-2.175-2.175v-2.175z",
});
