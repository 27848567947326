import React from "react";
import { Grid } from "@chakra-ui/core";

// PROPS
interface Props {
    children: React.ReactNode;
}

// MAIN COMPONENT
export const LoadLayout: React.FC<Props> = ({ children }) => {
    return (
        <Grid
            templateColumns={{
                base: "repeat(auto-fill, minmax(130px, 1fr))",
                md: "repeat(auto-fill, minmax(200px, 1fr))",
            }}
            gap={{ base: "20px", md: "25px" }}
        >
            {children}
        </Grid>
    );
};
