import React, { useState, useEffect } from "react";
import axios from "axios";
import { Redirect } from "react-router-dom";
import { SetCredentials, GetBaseUrl, GetToken } from "utils";
import { Flex, Button, HStack, Text, useToast, VStack } from "@chakra-ui/core";
import { InputBox } from "components/curiousfly";
import { CflyHome } from "assets/icons/ui";

// MAIN COMPONENT
export default function Login() {
    //Retrieving values from username and password input fields
    const [inputState, setInputState] = useState(
        { name: "", password: "" } || {}
    );
    function updateState(event: any) {
        const { name, value } = event.target;
        setInputState((prevValues) => ({ ...prevValues, [name]: value }));
    }

    //Generating a random number for login
    const randomNumber = Math.floor(Math.random() * 10000000000000 + 1);

    //Declaring API Endpoints and initial api_data
    const api_endpoint = "/api/v0/app/login";
    var api_data = {
        username: inputState.name,
        password: inputState.password,
        device_type: 1,
        device_push_id: "PID" + randomNumber.toString(),
        device_id: randomNumber,
        device_name: "Web Browser",
        app_version: "React Web App 1.0",
    };

    //Handle Click and Data Preparation
    const [isClicked, setIsClicked] = useState(false);
    function submitLogin(event: any) {
        setIsClicked(true);
    }

    //Api Call
    const [apiState, apiSetState] = useState({
        data: {},
        success: false,
        message: "",
    });
    const toast = useToast();
    useEffect(() => {
        const fetchItems = async () => {
            try {
                if (isClicked) {
                    const base_url = GetBaseUrl();
                    apiSetState({ data: {}, success: false, message: "" });
                    await axios({
                        method: "post",
                        url: api_endpoint,
                        baseURL: base_url,
                        data: api_data,
                    })
                        .then(function (result) {
                            apiSetState({
                                data: result.data.data,
                                success: result.data.success,
                                message: result.data.message,
                            });
                            if (!result.data.success) {
                                toast({
                                    title: "Login Failed",
                                    description: result.data.message,
                                    status: "error",
                                    duration: 5000,
                                    isClosable: true,
                                });
                            }
                        })
                        .catch(function (error) {
                            console.log(error);
                        })
                        .finally(function () {
                            setIsClicked(false);
                        });
                }
            } catch (error) {
                console.log(error);
            }
        };
        fetchItems();
        // eslint-disable-next-line
    }, [isClicked]);

    // Get Token, validate and redirect to home page
    const token = GetToken();
    if (token !== null) {
        return <Redirect to="/home" />;
    }

    // Redirect to home page if successfull
    if (apiState.success === true) {
        SetCredentials(apiState.data);
        //Redirect to Home Page
        return <Redirect to="/home" />;
    }

    // Rendering the Form for Login
    return (
        <Flex direction="column" align="center" justify="center" h="100vh">
            <HStack mb="50px" align="center" justify="center">
                <CflyHome fontSize="50px" mb="20px" />
                <Text fontSize="30px">Curiousfly</Text>
            </HStack>
            <VStack px="20px" w={{ base: "100vw", lg: "50vw" }}>
                <InputBox
                    name="name"
                    type="text"
                    placeholder="Username"
                    getValue={updateState}
                    value={inputState.name}
                />
                <InputBox
                    name="password"
                    type="password"
                    placeholder="Password"
                    getValue={updateState}
                    value={inputState.password}
                />
            </VStack>
            <Button
                colorScheme="teal"
                onClick={submitLogin}
                isLoading={isClicked}
            >
                LOGIN
            </Button>
        </Flex>
    );
}
