import React from "react";
import { Flex, useColorModeValue, Text } from "@chakra-ui/core";
import { shadow_dark_mode, shadow_day_mode } from "assets/theme/theme";

interface Props {
    name: string;
    click_fn?: any;
}

export const Option: React.FC<Props> = ({ name, click_fn }) => {
    // Setting the colors
    const bgColor = useColorModeValue("white", "dark_main_nav");
    const shadowColor = useColorModeValue(shadow_day_mode, shadow_dark_mode);
    return (
        <Flex
            direction="column"
            align="flex-start"
            justify="center"
            bg={bgColor}
            borderRadius="6px"
            boxShadow={shadowColor}
            h="60px"
            w="100%"
            mt="15px"
            pl="30px"
            onClick={() => click_fn()}
        >
            <Text>{name}</Text>
        </Flex>
    );
};
