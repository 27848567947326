import React, { useState } from "react";
import axios from "axios";
import { Button, Divider, Flex, Grid, Text, useToast } from "@chakra-ui/core";
import { load_active_gradient } from "assets/theme/theme";
import { GetBaseUrl, GetToken } from "utils";
import { Redirect } from "react-router-dom";

interface Props {
    map: number;
    load: string;
    channel: string;
}

export const LoadChannelMap: React.FC<Props> = ({ load, channel, map }) => {
    // SET THE COMPONENT VIEW
    const [componentView, setComponentView] = useState(1);

    // API CALL FOR REMOVING LOAD CHANNEL MAP
    const toast = useToast();
    const [apiState, setApiState] = useState<boolean>();
    const deleteMap = async () => {
        try {
            const base_url = GetBaseUrl();
            const session_key = GetToken();
            await axios({
                method: "post",
                url: "/api/v0/app/deleteLoadChannelMap",
                baseURL: base_url,
                data: {
                    map_id: map,
                },
                headers: {
                    Authorization: `Curious ${session_key}`,
                },
            })
                .then(function (result: any) {
                    console.log(result.data);
                    if (!result.data.success) {
                        toast({
                            title: "Map Deletion Failed",
                            description: result.data.message,
                            status: "error",
                            duration: 5000,
                            isClosable: true,
                        });
                    } else {
                        toast({
                            title: "Map Deletion Successful",
                            description: result.data.message,
                            status: "success",
                            duration: 5000,
                            isClosable: true,
                        });
                        setApiState(result.data.success);
                    }
                })
                .catch(function (error: any) {
                    console.log(error);
                });
        } catch (error) {
            console.log(error);
        }
    };

    // Redirect to mapping page if successfull
    if (apiState === true) {
        return <Redirect to="/lights/mapping" />;
    }

    return (
        <Grid
            templateColumns={componentView === 1 ? "40% auto 40%" : "60% auto"}
            bg={load_active_gradient}
            height="90px"
            mt="15px"
            placeItems="center"
            borderRadius="12px"
            onClick={() => {
                if (componentView === 1) {
                    setComponentView(2);
                } else {
                    setComponentView(1);
                }
            }}
        >
            {componentView === 2 && (
                <>
                    <Flex
                        align="center"
                        justify="center"
                        px="10px"
                        textAlign="center"
                    >
                        <Text color="white">Delete Mapping ?</Text>
                    </Flex>
                    <Button
                        colorScheme="red"
                        onClick={() => deleteMap()}
                        zIndex={200}
                    >
                        Confirm
                    </Button>
                </>
            )}
            {componentView === 1 && (
                <>
                    <Flex
                        align="center"
                        justify="center"
                        px="10px"
                        textAlign="center"
                    >
                        <Text color="white">{load}</Text>
                    </Flex>
                    <Divider />
                    <Flex
                        align="center"
                        justify="center"
                        px="10px"
                        textAlign="center"
                    >
                        <Text color="white">{channel}</Text>
                    </Flex>
                </>
            )}
        </Grid>
    );
};
