import React from "react";
import { Box } from "@chakra-ui/core";

// PROPS
interface Props {
    children: React.ReactNode;
}

// MAIN COMPONENT
export const ContentBox: React.FC<Props> = ({ children }) => {
    return (
        <Box bg="gray.200" mt="10px" p="10px" borderRadius="6px" pl="20px">
            {children}
        </Box>
    );
};
