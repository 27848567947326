import React from "react";
import { Input } from "@chakra-ui/core";

// PROPS
interface Props {
    name: string;
    type: string;
    placeholder?: string;
    value: string | number;
    getValue: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

// BUILDING THE UI
export const InputBox: React.FC<Props> = ({
    name,
    type,
    placeholder,
    getValue,
    value,
}) => {
    return (
        <Input
            name={name}
            type={type}
            placeholder={placeholder}
            onChange={getValue}
            value={value}
            size="md"
            w={{ base: "100%", md: "30vw" }}
            mb="20px"
        />
    );
};
