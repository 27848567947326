import React from "react";
import { createIcon } from "@chakra-ui/icon";

export const CflyDefaultScene = createIcon({
    displayName: "CflyDefaultScene",
    viewBox: "0 0 24 24",
    d:
        "M2.211 3a2.292 2.292 0 0 0-2.21 2.4v8.553l3.155-2.918a.357.357 0 0 1 .5.01l3.916 3.64 7.438-6.938a.369.369 0 0 1 .174-.09.356.356 0 0 1 .293.07l8.529 6.517V5.401a2.291 2.291 0 0 0-2.21-2.4H2.211zm6.034 2.336c.026 0 .048.019.073.02a2.516 2.516 0 0 0 .009 4.372c-.029 0-.053.02-.083.02a2.215 2.215 0 0 1 0-4.412zm7.034 3.259l-7.465 6.948a.357.357 0 0 1-.5 0l-3.925-3.65L0 15.032v4.061a2.311 2.311 0 0 0 2.21 2.416h19.581a2.31 2.31 0 0 0 2.21-2.416v-3.84z",
});

export const CflyMusic = createIcon({
    displayName: "CflyMusic",
    viewBox: "0 0 24 24",
    d:
        "M11.289 0v15.058a4.952 4.952 0 0 0-2.584-.718 4.366 4.366 0 1 0 4.706 4.353V4.217s3.075-1.445 5.953 3.144C18.853-.064 14.737 2 11.289 0z",
});

export const CflyMovie = createIcon({
    displayName: "CflyMovie",
    viewBox: "0 0 24 24",
    d:
        "M21.557 1H2.443A1.443 1.443 0 0 0 1 2.443v19.114A1.443 1.443 0 0 0 2.443 23h19.114A1.443 1.443 0 0 0 23 21.557V2.443A1.442 1.442 0 0 0 21.557 1zm-10.68 1.446h2.246v2.247h-2.246zM4.693 21.508H2.447v-2.246h2.246v2.246zm0-16.815H2.447V2.446h2.246v2.247zm4.215 16.815H6.662v-2.246h2.246zm0-16.815H6.662V2.446h2.246zm4.215 16.815h-2.246v-2.246h2.246zm-4.016-6.162V8.653L14.893 12zm8.232 6.162h-2.247v-2.246h2.246zm0-16.815h-2.247V2.446h2.246zm4.215 16.815h-2.247v-2.246h2.246zm0-16.815h-2.247V2.446h2.246z",
});

export const CflyNight = createIcon({
    displayName: "CflyNight",
    viewBox: "0 0 24 24",
    d:
        "M20.653 16.991a12.863 12.863 0 0 1-1.5-.118 10.905 10.905 0 0 1-9.077-12.426 13.815 13.815 0 0 1 .353-1.5.974.974 0 0 0-1.263-1.175 10.894 10.894 0 1 0 12.251 16.779.981.981 0 0 0-.764-1.56z",
});

export const CflyAway = createIcon({
    displayName: "CflyAway",
    viewBox: "0 0 24 24",
    d:
        "M17.071 1a2.167 2.167 0 1 1-2.3 2.164A2.228 2.228 0 0 1 17.071 1zm4.591 11.917h-3.214a1.067 1.067 0 0 1-.918-.5l-1.907-3.062-1.945 4.294 2.366 2.263a.9.9 0 0 1 .283.5l1.059 4.394h1.448a1.034 1.034 0 1 1 0 2.064h-2.256a1.082 1.082 0 0 1-1.059-.8l-1.2-4.893-2.543-2.5-1.627 3.133a1.113 1.113 0 0 1-.953.566H4.778l-.742 1.265a1.126 1.126 0 0 1-.953.533 1.044 1.044 0 0 1-.53-.133 1 1 0 0 1-.424-1.4l1.059-1.8a1.083 1.083 0 0 1 .953-.533h4.344l1.978-3.795a.118.118 0 0 1 .035-.1l2.26-4.96h-3.28l-1.554 2.5a1.079 1.079 0 0 1-.918.5.963.963 0 0 1-.53-.133.989.989 0 0 1-.388-1.4l1.872-3a1.113 1.113 0 0 1 .918-.5h6.5c.035 0 .071.033.106.033s.071.033.106.033.071.033.106.033.071.033.106.067l.071.067c.035.033.071.033.071.067l.071.067c.035.033.035.067.071.1l.035.033.071.1a1.664 1.664 0 0 1 .318.533l2.649 4.294h2.578a1.07 1.07 0 0 1 1.095 1.032 1.188 1.188 0 0 1-1.17 1.038z",
});

export const CflySleep = createIcon({
    displayName: "CflySleep",
    viewBox: "0 0 24 24",
    path: (
        <g fill="currentColor" transform="translate(1 6)">
            <path d="M0 7.89h21.244v5.036H0z" />
            <path d="M8.809 4.992v1.4a.774.774 0 0 1-.772.772H2.345a.774.774 0 0 1-.772-.772v-2.3a.751.751 0 0 1 .131-.429z" />
            <path d="M22.159 3.518a.885.885 0 0 1-.022.208l-.292 1.3-.028.1-.365 1.437-.006.006a2.013 2.013 0 0 1-1.033.471 1.641 1.641 0 0 1-.292.022h-13.4a2.037 2.037 0 1 1 0-4.074h6.807l1.184 2.037h5.186l.387-1.717a.945.945 0 0 1 1.869.208z" />
            <ellipse
                cx="1.964"
                cy="1.964"
                rx="1.964"
                ry="1.964"
                transform="translate(4.434)"
            />
        </g>
    ),
});

export const CflyMorning = createIcon({
    displayName: "CflyMorning",
    viewBox: "0 0 24 24",
    d:
        "M18.757 12.094a6.663 6.663 0 1 1-6.663-6.663 6.663 6.663 0 0 1 6.663 6.663zm-6.663 7.774a.278.278 0 0 0-.278.278v2.221a.278.278 0 0 0 .278.278.278.278 0 0 0 .278-.278v-2.223a.278.278 0 0 0-.278-.276zm10.272-8.057h-2.222a.278.278 0 0 0 0 .555h2.221a.278.278 0 1 0 0-.555zm-18.324 0h-2.22a.28.28 0 0 0 0 .561h2.222a.28.28 0 0 0 0-.561zm13.937 5.78a.278.278 0 0 0-.394 0 .272.272 0 0 0 0 .389l1.572 1.564a.279.279 0 0 0 .394-.394zM6.208 6.597a.289.289 0 0 0 .194.078.283.283 0 0 0 .2-.083.272.272 0 0 0 0-.389L5.031 4.637a.283.283 0 0 0-.394 0 .278.278 0 0 0 0 .394zm0 10.994l-1.577 1.566a.279.279 0 0 0 .394.394l1.571-1.566a.272.272 0 0 0 0-.389.278.278 0 0 0-.388-.005zM19.163 4.637l-1.572 1.566a.272.272 0 0 0 0 .389.283.283 0 0 0 .2.083.289.289 0 0 0 .194-.078l1.577-1.566a.281.281 0 1 0-.4-.394zm-7.069-.317a.278.278 0 0 0 .278-.278v-2.22a.278.278 0 0 0-.278-.278.278.278 0 0 0-.278.278v2.222a.278.278 0 0 0 .278.276z",
});

export const CflyPanic = createIcon({
    displayName: "CflyPanic",
    viewBox: "0 0 24 24",
    path: (
        <g fill="currentColor">
            <path d="M12 3a6.86 6.86 0 0 0-6.85 6.85v6.85H18.9V9.85A6.936 6.936 0 0 0 12 3zm-1.5 2a5.055 5.055 0 0 0-3.6 4.85v5.7H6v-5.7a6 6 0 0 1 4.5-5.8z" />
            <path d="M19.75 17.6H4.2a1.743 1.743 0 0 0-1.65 1.7 1.686 1.686 0 0 0 1.7 1.7h15.4a1.686 1.686 0 0 0 1.7-1.7 1.664 1.664 0 0 0-1.6-1.7z" />
            <path d="M20.7 5.8l1.5-1.25a.5.5 0 1 0-.65-.75l-1.5 1.25a.479.479 0 0 0-.05.7.486.486 0 0 0 .7.05z" />
            <path d="M23.5 8.2h-1.95a.5.5 0 0 0-.5.5.472.472 0 0 0 .5.5h1.95a.5.5 0 0 0 .5-.5.472.472 0 0 0-.5-.5z" />
            <path d="M20.7 11.6a.5.5 0 0 0-.65.75l1.5 1.25a.5.5 0 1 0 .65-.75z" />
            <path d="M3.3 5.8a.486.486 0 0 0 .7-.05.535.535 0 0 0-.05-.7L2.45 3.8a.535.535 0 0 0-.7.05.535.535 0 0 0 .05.7z" />
            <path d="M2.9 8.7a.5.5 0 0 0-.5-.5H.5a.5.5 0 0 0-.5.5.472.472 0 0 0 .5.5h1.95a.493.493 0 0 0 .45-.5z" />
            <path d="M4 11.65a.479.479 0 0 0-.7-.05l-1.5 1.25a.427.427 0 0 0-.05.65.479.479 0 0 0 .7.05l1.5-1.25a.524.524 0 0 0 .05-.65z" />
        </g>
    ),
});
