import React from "react";
import { Box } from "@chakra-ui/core";

// PROPS
interface Props {
    children: React.ReactNode;
}

// MAIN COMPONENT
export const PaddedBox: React.FC<Props> = ({ children }) => {
    return (
        <Box
            pl={{ base: "10px", lg: "10vw" }}
            pr={{ base: "10px", lg: "2vw" }}
            pt={{ base: "10px", lg: "5vh" }}
            pb={{ base: "10vh", lg: "5vh" }}
        >
            {children}
        </Box>
    );
};
