import React, { useState } from "react";
import axios from "axios";
import { CflyMenuDrawer } from "assets/icons/ui";
import { Link, Redirect } from "react-router-dom";
import { MoonIcon, SunIcon } from "@chakra-ui/icons";
import { GetBaseUrl, GetToken, RemoveCredentials } from "utils";
import {
    Text,
    Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    useDisclosure,
    useColorMode,
    useColorModeValue,
    Divider,
    HStack,
    Switch,
} from "@chakra-ui/core";

export default function DrawerComponent() {
    // Theme Functions
    const { colorMode, toggleColorMode } = useColorMode();
    // Drawer Functions
    const { isOpen, onOpen, onClose } = useDisclosure();
    // Setting colors for drawer
    const drawerBgColor = useColorModeValue("theme_light", "theme_dark");

    // Logout API Endpoints and Call
    const [logoutState, handleLogoutState] = useState(false);
    function handleLogout() {
        const fetchItems = async () => {
            const base_url = GetBaseUrl();
            const session_key = GetToken();
            const logout_api_endpoint = "/api/v0/app/logout";
            await axios({
                method: "get",
                url: logout_api_endpoint,
                baseURL: base_url,
                headers: { Authorization: `Curious ${session_key}` },
            })
                .then(function (result) {
                    if (result.data.success) {
                        handleLogoutState(true);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        };
        fetchItems();
        // eslint-disable-next-line
    }

    // Handling Logout
    if (logoutState) {
        RemoveCredentials();
        return <Redirect to="/" />;
    }

    return (
        <>
            <CflyMenuDrawer onClick={onOpen} mb="3" cursor="pointer" />
            <Drawer
                isOpen={isOpen}
                placement="left"
                onClose={onClose}
                size="full"
            >
                <DrawerOverlay />
                <DrawerContent bg={drawerBgColor}>
                    <DrawerCloseButton />
                    <DrawerHeader>Curiousfly</DrawerHeader>
                    <DrawerBody>
                        <Divider my="10px" />
                        <Link to="/users">
                            <Text>Users</Text>
                        </Link>
                        <Divider my="10px" />
                        <a href="curioushub://setup">
                            <Text cursor="pointer">Hub Configuration</Text>
                        </a>
                        <Divider my="10px" />
                        <Link to="/book/complaint">
                            <Text>Book a complaint</Text>
                        </Link>
                        <Divider my="10px" />
                        <HStack justify="space-between">
                            <Text>Theme</Text>
                            <HStack>
                                <SunIcon
                                    color={
                                        colorMode === "light"
                                            ? "yellow.500"
                                            : "gray.500"
                                    }
                                />
                                <Switch
                                    onChange={toggleColorMode}
                                    isChecked={colorMode === "dark" && true}
                                />
                                <MoonIcon
                                    color={
                                        colorMode === "light"
                                            ? "gray.200"
                                            : "blue.600"
                                    }
                                />
                            </HStack>
                        </HStack>
                        <Divider my="10px" />
                        <Link to="/config">
                            <Text>Configuration</Text>
                        </Link>
                        <Divider my="10px" />
                        <Text onClick={() => handleLogout()}>Logout</Text>
                        <Divider my="10px" />
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </>
    );
}
