import React from "react";
import { Grid, Square, Switch, Text, VStack } from "@chakra-ui/core";
import { CflyDefaultScene } from "assets/icons/scene";
import { load_active_gradient } from "assets/theme/theme";

interface Props {
    id: string;
    status: number;
    time: string;
    name: string;
}

export const TimerComponent: React.FC<Props> = ({ id, status, time, name }) => {
    return (
        <>
            <Grid
                templateColumns="30% auto 20%"
                key={"T" + id}
                bg={load_active_gradient}
                height="90px"
                mt="15px"
                placeItems="center"
                borderRadius="12px"
            >
                <Square bg="rgba(255,255,255, 0.5)" size="55px">
                    <CflyDefaultScene h="22px" w="22px" color="white" />
                </Square>
                <VStack>
                    <Text color="white">{name}</Text>
                    <Text color="white">{time}</Text>
                </VStack>
                <Switch
                    size="md"
                    isChecked={status === 1 ? true : false}
                    colorScheme="teal"
                />
            </Grid>
        </>
    );
};
