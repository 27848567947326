import React from "react";
import { CflyFan, CflyLightDim, CflyDefaultLoad } from "assets/icons/loads";
import { Flex, Grid, Text, useColorModeValue } from "@chakra-ui/core";
import {
    shadow_day_mode,
    shadow_dark_mode,
    load_active_gradient,
} from "assets/theme/theme";

// PROPS
interface Props {
    id: string;
    name: string;
    type: number;
    state: number;
    value: number;
}

// MAIN COMPONENT
export const Load: React.FC<Props> = ({ id, name, type, state, value }) => {
    function IconPicker() {
        if (type === 3) {
            return <CflyFan color="white" w="20px" h="20px" />;
        } else if (type === 5) {
            return <CflyLightDim color="white" w="20px" h="20px" />;
        } else {
            return <CflyDefaultLoad color="white" w="20px" h="20px" />;
        }
    }

    //Setting the shadow color
    const shadowColor = useColorModeValue(shadow_day_mode, shadow_dark_mode);

    // BUILDING THE UI
    return (
        <Grid
            key={"L" + id}
            gridTemplateColumns="auto"
            gridTemplateRows="50% 50%"
            bg={state === 1 ? load_active_gradient : "load_inactive"}
            color="white"
            borderRadius="6px"
            pl="10px"
            h={{ base: "70px", md: "100px" }}
            boxShadow={shadowColor}
            opacity={state === 0 ? 0.35 : 1}
            my="15px"
        >
            <Flex align="center" justify="space-between" pr="20px">
                <Text>{name}</Text>
                {IconPicker()}
            </Flex>
        </Grid>
    );
};
