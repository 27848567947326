import React, { useState } from "react";
import { CflyLightDim } from "assets/icons/loads";
import {
    Flex,
    Grid,
    Slider,
    SliderFilledTrack,
    SliderThumb,
    SliderTrack,
    Text,
    useColorModeValue,
} from "@chakra-ui/core";
import {
    shadow_day_mode,
    shadow_dark_mode,
    load_active_gradient,
} from "assets/theme/theme";

// PROPS
interface Props {
    id: string;
    name: string;
    state: number;
    scene_data_fn?: any;
    scene_name_fn?: any;
}

// MAIN COMPONENT
export const LoadSceneComponent: React.FC<Props> = ({
    id,
    name,
    state,
    scene_data_fn,
    scene_name_fn,
}) => {
    //Setting the shadow color
    const shadowColor = useColorModeValue(shadow_day_mode, shadow_dark_mode);

    const [onSliderState, setOnSliderState] = useState(0);
    const [offSliderState, setOffSliderState] = useState(0);

    function createApiData() {
        scene_name_fn((prevValue: any) => [...prevValue, name]);
        scene_data_fn((prevValue: any) => [
            ...prevValue,
            {
                load_id: id,
                on_brightness: onSliderState,
                off_brightness: offSliderState,
                type: 1,
            },
        ]);
    }

    // BUILDING THE UI
    return (
        <Grid
            key={"L" + id}
            gridTemplateColumns="auto"
            gridTemplateRows="40% auto auto"
            bg={state === 1 ? load_active_gradient : "load_inactive"}
            color="white"
            borderRadius="6px"
            pl="10px"
            h={{ base: "90px", md: "100px" }}
            boxShadow={shadowColor}
            opacity={state === 0 ? 0.35 : 1}
            my="15px"
            onClick={() => createApiData()}
        >
            <Flex align="center" justify="space-between" pr="20px">
                <Text>{name}</Text>
                <CflyLightDim color="white" w="20px" h="20px" />
            </Flex>
            <Flex align="center" justify="space-around">
                <Text>ON</Text>
                <Slider
                    defaultValue={onSliderState}
                    onChange={(e) => setOnSliderState(e)}
                    min={0}
                    max={100}
                    w="60%"
                >
                    <SliderTrack
                        bg={
                            state === 1
                                ? "slider_track_active"
                                : "slider_track_inactive"
                        }
                        h="10px"
                        borderRadius="10px"
                    >
                        <SliderFilledTrack
                            bg={state === 1 ? "slider_fill_active" : "divider"}
                        />
                    </SliderTrack>
                    <SliderThumb boxSize={5} />
                </Slider>
                <Text>{onSliderState}</Text>
            </Flex>
            <Flex align="center" justify="space-around">
                <Text>OFF</Text>
                <Slider
                    defaultValue={offSliderState}
                    onChange={(e) => setOffSliderState(e)}
                    min={0}
                    max={100}
                    w="60%"
                >
                    <SliderTrack
                        bg={
                            state === 1
                                ? "slider_track_active"
                                : "slider_track_inactive"
                        }
                        h="10px"
                        borderRadius="10px"
                    >
                        <SliderFilledTrack
                            bg={state === 1 ? "slider_fill_active" : "divider"}
                        />
                    </SliderTrack>
                    <SliderThumb boxSize={5} />
                </Slider>
                <Text>{offSliderState}</Text>
            </Flex>
        </Grid>
    );
};
