import GetValueFromObject from "utils/getValueFromObject";

// TODO: Find better method to store data (Remove use of local storage)
export default function GetToken() {
    const session_key = localStorage.getItem("CURIOUS_TOKEN");
    return session_key;
}

export function GetUserName() {
    const curious_user = localStorage.getItem("CURIOUS_USER");
    return curious_user;
}

export function SetCredentials(api_data: any) {
    //Removing old Data
    localStorage.removeItem("CURIOUS_TOKEN");
    localStorage.removeItem("CURIOUS_USER");
    //Converting Object Data to be accessible by typescript
    const api_token_value = GetValueFromObject(api_data, "token");
    const user_first_name = GetValueFromObject(api_data, "first_name");
    //Setting the value in localstorage
    localStorage.setItem("CURIOUS_TOKEN", api_token_value);
    localStorage.setItem("CURIOUS_USER", user_first_name);
}

export function RemoveCredentials() {
    //Removing old Data
    localStorage.removeItem("CURIOUS_TOKEN");
    localStorage.removeItem("CURIOUS_USER");
}
