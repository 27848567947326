import React from "react";
import { Circle, Flex, useColorModeValue } from "@chakra-ui/core";
import { Link } from "react-router-dom";
import { CflyRoom, CflyHome, CflyDashboard } from "assets/icons/ui";

// Creating the Link Child Component
function NavItem(props: any) {
    const navItemColor = useColorModeValue("main_nav_icon_light", "white");
    const iconColor = useColorModeValue("white", "black");

    return (
        <Link to={props.link}>
            <Circle size="40px" bg={navItemColor} boxShadow="0px 2px 4px black">
                {props.iconName === "rooms" && <CflyRoom color={iconColor} />}
                {props.iconName === "home" && <CflyHome color={iconColor} />}
                {props.iconName === "dashboard" && (
                    <CflyDashboard color={iconColor} />
                )}
            </Circle>
        </Link>
    );
}

// BUILDING THE UI
export default function MainNav() {
    const mainNavColor = useColorModeValue("white", "dark_main_nav");

    return (
        <Flex
            bg={mainNavColor}
            direction={{ base: "row", lg: "column" }}
            borderRadius={{
                base: "30px 30px 0px 0px",
                lg: "10px 40px 40px 10px",
            }}
            justify="space-around"
            align="center"
            position="fixed"
            bottom={{ base: "0", lg: "35vh" }}
            left={{ lg: "0" }}
            w={{ base: "100vw", lg: "70px" }}
            h={{ base: "50px", lg: "30vh" }}
            boxShadow="0px 2px 4px black"
        >
            <NavItem link="/rooms" iconName="rooms" />
            <NavItem link="/home" iconName="home" />
            <NavItem link="/dashboard" iconName="dashboard" />
        </Flex>
    );
}
