import React, { lazy, Suspense } from "react";
import Login from "pages/main/Login";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Flex, Spinner } from "@chakra-ui/core";

// LAZY IMPORTS FOR ROUTE BASED CODE SPLITTING

const Home = lazy(() => import("pages/main/Home"));
const Rooms = lazy(() => import("pages/rooms/Rooms"));
const RoomLoads = lazy(() => import("pages/rooms/RoomLoads"));
const Scenes = lazy(() => import("pages/scene/Scenes"));
const Schedulers = lazy(() => import("pages/scheduler/Schedulers"));
const Timers = lazy(() => import("pages/timer/Timers"));
const Dashboard = lazy(() => import("pages/main/Dashboard"));
const Features = lazy(() => import("pages/main/Features"));
const Mapping = lazy(() => import("pages/lights/LightMapping"));
const MccDifferent = lazy(() => import("pages/lights/MccDifferent"));
const AddScene = lazy(() => import("pages/scene/AddScenes"));
const AddScheduler = lazy(() => import("pages/scheduler/AddSchedulers"));
const VoiceControl = lazy(() => import("pages/voice/Voice"));
const Away = lazy(() => import("pages/away/Away"));
const Panic = lazy(() => import("pages/panic/Panic"));
const LongTouch = lazy(() => import("pages/lights/LongTouch"));
const AddVoice = lazy(() => import("pages/voice/AddVoice"));
const AddPanic = lazy(() => import("pages/panic/AddPanic"));
const Users = lazy(() => import("pages/account/Users"));
const AddUsers = lazy(() => import("pages/account/AddUsers"));
const Complaint = lazy(() => import("pages/main/BookComplaints"));
const LightsAll = lazy(() => import("pages/lights/AllLights"));
const LightsConfig = lazy(() => import("pages/config/lights/LightsConfig"));
const DiscoverBcDevice = lazy(() => import("pages/lights/DiscoverBcDevice"));
const SceneInfo = lazy(() => import("pages/scene/SceneInfo"));
const AddAway = lazy(() => import("pages/away/AddAway"));
const SchedulerInfo = lazy(() => import("pages/scheduler/SchedulerInfo"));
const TimerInfo = lazy(() => import("pages/timer/TimerInfo"));
const LoadInfo = lazy(() => import("pages/config/load/LoadInfo"));
const AmberInfo = lazy(() => import("pages/config/amber/AmberInfo"));
const AddMapping = lazy(() => import("pages/lights/AddMapping"));
const MainConfig = lazy(() => import("pages/config/MainConfig"));
const HomeConfig = lazy(() => import("pages/config/home/HomeInfo"));
const RoomConfig = lazy(() => import("pages/config/room/RoomInfo"));
const AddTimer = lazy(() => import("pages/timer/AddTimer"));
const ChannelConfig = lazy(() => import("pages/config/brightness_channel/brightness_channel"));
const ControllerConfig = lazy(() => import("pages/config/brightness_controller/brightness_controller"));

// SUSPENSE FALLBACK COMPONENT

function Loading() {
    return (
        <Flex align="center" justify="center" h="100vh" w="100vw">
            <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                size="xl"
            />
        </Flex>
    );
}

// MAIN COMPONENT
export default function App() {
    // BUILDING THE ROUTING

    return (
        <Router>
            <Suspense fallback={Loading}>
                <Switch>
                    <Route exact path="/">
                        <Login />
                    </Route>
                    <Route exact path="/home">
                        <Home />
                    </Route>
                    <Route exact path="/rooms">
                        <Rooms />
                    </Route>
                    <Route exact path="/rooms/:room_id/:room_name">
                        <RoomLoads />
                    </Route>
                    <Route exact path="/scenes">
                        <Scenes />
                    </Route>
                    <Route exact path="/schedulers">
                        <Schedulers />
                    </Route>
                    <Route exact path="/timers">
                        <Timers />
                    </Route>
                    <Route exact path="/dashboard">
                        <Dashboard />
                    </Route>
                    <Route exact path="/features">
                        <Features />
                    </Route>
                    <Route exact path="/lights">
                        <LightsAll />
                    </Route>
                    <Route exact path="/lights/mapping">
                        <Mapping />
                    </Route>
                    <Route exact path="/lights/mcc/different">
                        <MccDifferent />
                    </Route>
                    <Route exact path="/add/scene">
                        <AddScene />
                    </Route>
                    <Route exact path="/add/scheduler">
                        <AddScheduler />
                    </Route>
                    <Route exact path="/voice">
                        <VoiceControl />
                    </Route>
                    <Route exact path="/away">
                        <Away />
                    </Route>
                    <Route exact path="/panic">
                        <Panic />
                    </Route>
                    <Route exact path="/lights/longtouch">
                        <LongTouch />
                    </Route>
                    <Route exact path="/add/voice">
                        <AddVoice />
                    </Route>
                    <Route exact path="/add/panic">
                        <AddPanic />
                    </Route>
                    <Route exact path="/users">
                        <Users />
                    </Route>
                    <Route exact path="/add/user">
                        <AddUsers />
                    </Route>
                    <Route exact path="/book/complaint">
                        <Complaint />
                    </Route>
                    <Route exact path="/config/lights">
                        <LightsConfig />
                    </Route>
                    <Route exact path="/discover/bc">
                        <DiscoverBcDevice />
                    </Route>
                    <Route exact path="/scenes/:scene_id/:scene_name">
                        <SceneInfo />
                    </Route>
                    <Route exact path="/add/away">
                        <AddAway />
                    </Route>
                    <Route exact path="/schedulers/:scene_id/:scene_name">
                        <SchedulerInfo />
                    </Route>
                    <Route exact path="/timers/:scene_id/:scene_name">
                        <TimerInfo />
                    </Route>
                    <Route exact path="/config/load/:load_id">
                        <LoadInfo />
                    </Route>
                    <Route exact path="/config/amber/:amber_id">
                        <AmberInfo />
                    </Route>
                    <Route exact path="/lights/mapping/add">
                        <AddMapping />
                    </Route>
                    <Route exact path="/config">
                        <MainConfig />
                    </Route>
                    <Route exact path="/config/home">
                        <HomeConfig />
                    </Route>
                    <Route exact path="/config/room/:room_id">
                        <RoomConfig />
                    </Route>
                    <Route exact path="/add/timer">
                        <AddTimer />
                    </Route>
                    <Route exact path="/config/controller/:controller_id">
                        <ControllerConfig />
                    </Route>
                    <Route exact path="/config/channel/:channel_id">
                        <ChannelConfig />
                    </Route>
                </Switch>
            </Suspense>
        </Router>
    );
}
