import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import App from "components/app/App";
import * as serviceWorker from "./serviceWorker";
import { ChakraProvider } from "@chakra-ui/core";
import curiousTheme from "assets/theme/theme";

ReactDOM.render(
    <React.StrictMode>
        <ChakraProvider resetCSS theme={curiousTheme}>
            <App />
        </ChakraProvider>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
