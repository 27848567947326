import React from "react";
import {
    Slider,
    SliderTrack,
    SliderFilledTrack,
    SliderThumb,
} from "@chakra-ui/core";

interface Props {
    type: number;
    value: number;
    state: number;
    updater?: any;
    updaterEnd?: any;
}

// MAIN COMPONENT
export const SliderComponent: React.FC<Props> = ({
    type,
    value,
    state,
    updater,
    updaterEnd,
}) => {
    return (
        <>
            {type === 1 && (
                <Slider
                    defaultValue={value}
                    onChange={updater}
                    onChangeEnd={updaterEnd}
                    min={0}
                    max={5}
                    w="80%"
                >
                    <SliderTrack
                        bg={
                            state === 1
                                ? "slider_track_active"
                                : "slider_track_inactive"
                        }
                        h="10px"
                        borderRadius="10px"
                    >
                        <SliderFilledTrack
                            bg={state === 1 ? "slider_fill_active" : "divider"}
                        />
                    </SliderTrack>
                    <SliderThumb boxSize={5} />
                </Slider>
            )}
            {type === 2 && (
                <Slider
                    defaultValue={value}
                    onChange={updater}
                    onChangeEnd={updaterEnd}
                    min={0}
                    max={100}
                    w="80%"
                >
                    <SliderTrack
                        bg={
                            state === 1
                                ? "slider_track_active"
                                : "slider_track_inactive"
                        }
                        h="10px"
                        borderRadius="10px"
                    >
                        <SliderFilledTrack
                            bg={state === 1 ? "slider_fill_active" : "divider"}
                        />
                    </SliderTrack>
                    <SliderThumb boxSize={5} />
                </Slider>
            )}
        </>
    );
};
