import React from "react";
import { createIcon } from "@chakra-ui/icon";

// Menu Icon

export const CflyMenuDrawer = createIcon({
    displayName: "CflyMenuDrawer",
    viewBox: "0 0 24 24",
    d:
        "M2.557 1h7.787c.856 0 1.556.976 1.556 2.18s-.7 2.18-1.557 2.18H2.557C1.7 5.36 1 4.384 1 3.18S1.7 1 2.557 1zm10.9 17.441h7.786c.86 0 1.557.976 1.557 2.18s-.7 2.18-1.557 2.18h-7.785c-.86 0-1.557-.976-1.557-2.18s.699-2.18 1.557-2.18zm-10.9-8.72h18.688c.86 0 1.557.976 1.557 2.18s-.7 2.18-1.557 2.18H2.557C1.7 14.081 1 13.1 1 11.9s.7-2.179 1.557-2.179z",
});

// Nav Icons

export const CflyDashboard = createIcon({
    displayName: "CflyDashboard",
    viewBox: "0 0 24.07 24.07",
    d:
        "M2.407 0h4.814a2.407 2.407 0 0 1 2.407 2.407v4.814a2.407 2.407 0 0 1-2.407 2.407H2.407A2.407 2.407 0 0 1 0 7.221V2.407A2.407 2.407 0 0 1 2.407 0zm14.442 0h4.814a2.407 2.407 0 0 1 2.407 2.407v4.814a2.407 2.407 0 0 1-2.407 2.407h-4.814a2.407 2.407 0 0 1-2.407-2.407V2.407A2.407 2.407 0 0 1 16.849 0zm0 14.442h4.814a2.407 2.407 0 0 1 2.407 2.407v4.814a2.407 2.407 0 0 1-2.407 2.407h-4.814a2.407 2.407 0 0 1-2.407-2.407v-4.814a2.407 2.407 0 0 1 2.407-2.407zm-14.442 0h4.814a2.407 2.407 0 0 1 2.407 2.407v4.814a2.407 2.407 0 0 1-2.407 2.407H2.407A2.407 2.407 0 0 1 0 21.663v-4.814a2.407 2.407 0 0 1 2.407-2.407z",
});

export const CflyHome = createIcon({
    displayName: "CflyHome",
    viewBox: "0 0 24 24",
    path: (
        <g>
            <g fill="currentColor">
                <path d="M12.059 8.653c-2.012 2.056-3.909 4.008-5.824 5.94a5.043 5.043 0 0 1-1.121.857.705.705 0 0 1-.99-.15c-.272-.4-.051-.738.227-1.019q2.047-2.073 4.11-4.129c.888-.89 1.773-1.783 2.665-2.67.636-.632 1.083-.648 1.717-.015q3.364 3.356 6.708 6.732a.8.8 0 0 1 .248 1.024.677.677 0 0 1-.785.405 1.333 1.333 0 0 1-.557-.338q-3.053-3.091-6.086-6.2a3.739 3.739 0 0 1-.312-.437z" />
                <path d="M16.72 15.73a1.689 1.689 0 0 1 .759 1.679 7.411 7.411 0 0 1-.649 3.335 5.439 5.439 0 0 1-5.608 2.89 5.262 5.262 0 0 1-4.55-4.45 19.748 19.748 0 0 1-.114-2.372.877.877 0 0 1 .1-.412c.193-.373.406-.771.92-.672s.518.542.533.943c.024.652-.018 1.309.051 1.956a3.877 3.877 0 0 0 7.721-.7c-.014-.222-.012-.445-.029-.667a1.448 1.448 0 0 1 .866-1.53z" />
            </g>
            <g>
                <path
                    d="M7.731 5.023a2.584 2.584 0 0 0-1.58-1.037.1.1 0 0 0-.059-.011 40.075 40.075 0 0 0-3.971-.709c-.21-1.118-.274-2.069.059-2.23.844-.408 4.39 2.816 5.029 3.385a.117.117 0 0 1 .048.038 2.258 2.258 0 0 1 .474.564z"
                    fill="#58C1EE"
                />
                <path
                    d="M3.604 8.09C2.583 7.107-.41 4.133.047 3.477c.21-.306 1.064-.343 2.074-.246a38.711 38.711 0 0 0 1.021 3.966h.005a1.954 1.954 0 0 0 .457.893z"
                    fill="#58C1EE"
                />
                <path
                    d="M6.962 8.821a2.527 2.527 0 0 1-2.676-.107 2.793 2.793 0 0 1-.344-.279l-.005.005s-.124-.146-.333-.35a1.952 1.952 0 0 1-.457-.892h-.005a38.358 38.358 0 0 1-1.021-3.96 39.073 39.073 0 0 1 3.971.723.1.1 0 0 1 .059.018 2.589 2.589 0 0 1 1.58 1.041 1.318 1.318 0 0 1 .07.115 1.152 1.152 0 0 1 .086.135 2.606 2.606 0 0 1-.925 3.551z"
                    fill="#00A9E5"
                />
            </g>
        </g>
    ),
});

export const CflyRoom = createIcon({
    displayName: "CflyRoom",
    viewBox: "0 0 24 24",
    path: (
        <g fill="currentColor">
            <path d="M19.027 0a.521.521 0 0 1 .093.029l3.421.729a.483.483 0 0 1 .373.35l.777 3.237a.447.447 0 0 1-.124.408l-10.76 10.122-.031.029a5.785 5.785 0 0 1-1.742 5.571 6.73 6.73 0 0 1-5.412 1.717 3.065 3.065 0 0 1-3.359.9A2.785 2.785 0 0 1 .49 19.475a2.609 2.609 0 0 1 .249-.467 5.8 5.8 0 0 1 1.151-7.116 6.7 6.7 0 0 1 5.971-1.6l.031-.029L18.654.175a.507.507 0 0 1 .28-.146.521.521 0 0 1 .093-.029zm.124 1.021l-10.232 9.6a6.44 6.44 0 0 1 .871.408l10.419-9.8zm2.208.438L10.63 11.579c.134.106.278.2.4.321a6 6 0 0 1 1.4 1.983l1.057-.992-.715-.671.715-.642.715.642 1.244-1.167-.684-.671.684-.671.715.671 1.275-1.2-.715-.642.715-.671.684.671 1.275-1.2-.684-.671.684-.642.715.642 1.275-1.167v-.029l-.705-.631.715-.671.684.671.591-.554-.653-2.683zm-14.9 9.6a5.7 5.7 0 0 0-3.888 1.517 4.907 4.907 0 0 0 0 7.262 5.581 5.581 0 0 0 2.55 1.342c.009-.02.023-.038.031-.058a1.78 1.78 0 0 0-.28-1.779 1.743 1.743 0 0 1-1.306-.437 1.56 1.56 0 0 1 0-2.275 1.783 1.783 0 0 1 2.395.029 1.493 1.493 0 0 1 0 2.217 1.455 1.455 0 0 1-.155.117 2.677 2.677 0 0 1 .311 2.333 5.456 5.456 0 0 0 5.344-3.055 4.8 4.8 0 0 0-1.145-5.695 5.623 5.623 0 0 0-3.854-1.523zM4.78 17.092a.715.715 0 0 0-.5.2.647.647 0 0 0-.218.5.525.525 0 0 1 .28.058 2.941 2.941 0 0 1 .809.437.629.629 0 0 0 .093-.058.6.6 0 0 0 0-.9.746.746 0 0 0-.464-.237zm-3.39 2.887a1.805 1.805 0 0 0 1.182 2.246 2.108 2.108 0 0 0 1.9-.263 6.454 6.454 0 0 1-2.581-1.487 6.271 6.271 0 0 1-.498-.496z" />
        </g>
    ),
});
